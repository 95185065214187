<template>
  <div>
    <a-collapse
      defaultActiveKey="0"
      :expand-icon-position="'right'"
      class="text-left"
      v-if="sourceFormData.length !== 0"
    >
      <a-collapse-panel
        :key="index + ''"
        :header="sourceFormData[index].sourceForm.name || '新建创意'"
        v-for="(itme, index) in sourceFormData"
      >
        <a-icon slot="extra" type="delete" @click="(e) => handleCreative(e, 'close', index)" />
        <a-form-model
          :model="sourceFormData[index].sourceForm"
          v-bind="layout"
          :ref="`sourceFormRef${index}`"
          class="text-left m-t-20"
        >
          <div class="flexStyle">
            <div class="flex-1">
              <a-form-model-item
                label="广告类型"
                prop="positionId"
                :rules="{
                  required: true,
                  message: '此项为必填项',
                  trigger: 'change'
                }"
              >
                <a-select
                  allowClear
                  v-model="sourceFormData[index].sourceForm.positionId"
                  placeholder="请选择广告类型"
                  @change="() => handlepositionId(index)"
                >
                  <a-select-option v-for="pitem in advTypeOptions" :key="pitem.value" :value="pitem.value">
                    {{ pitem.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="创意类型"
                prop="type"
                :rules="{
                  required: true,
                  message: '此项为必填项',
                  trigger: 'change'
                }"
              >
                <a-select
                  v-if="[5].includes(sourceFormData[index].sourceForm.positionId)"
                  allowClear
                  v-model="sourceFormData[index].sourceForm.type"
                  placeholder="请选择创意类型"
                >
                  <a-select-option v-for="pitem in creativeTypeVideoOptions" :key="pitem.value" :value="pitem.value">
                    {{ pitem.label }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else
                  allowClear
                  v-model="sourceFormData[index].sourceForm.type"
                  placeholder="请选择创意类型"
                >
                  <a-select-option v-for="pitem in creativeTypeOptions" :key="pitem.value" :value="pitem.value">
                    {{ pitem.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                prop="name"
                label="创意名称"
                :rules="{
                  required: true,
                  message: '此项为必填项',
                  trigger: 'change'
                }"
              >
                <a-input v-model.trim="sourceFormData[index].sourceForm.name" allowClear placeholder="创意名称" />
              </a-form-model-item>
              <a-form-model-item
                label="图片"
                prop="imageUrl"
                class="hiddenError"
                :rules="[
                  {
                    required: true,
                    message: '此项为必填项',
                    trigger: 'change',
                    validator: handleImgErr
                  }
                ]"
              >
                <!-- :action="`http://172.16.11.62:8908/adPlan/uploadFile`" -->
                <div v-if="sourceFormData[index].sourceForm.imageUrl" class="elImageBox image_box">
                  <el-image
                    style="height: 100px; text-align: center"
                    :src="adplanResourceUrl + sourceFormData[index].sourceForm.imageUrl"
                    :preview-src-list="[adplanResourceUrl + sourceFormData[index].sourceForm.imageUrl]"
                  >
                  </el-image>
                  <div class="modal">
                    <div class="wrapper">
                      <a-icon class="deleteIcon" type="delete" @click="() => handleCloseImg(1, index)" />
                      <a-icon
                        class="deleteIcon"
                        type="eye"
                        @click="
                          () => handlePreviewImg(adplanResourceUrl + sourceFormData[index].sourceForm.imageUrl, 1)
                        "
                      />
                    </div>
                  </div>
                </div>
                <a-upload
                  v-else
                  list-type="picture-card"
                  class="avatar-uploader"
                  :before-upload="beforeUpload"
                  :action="'/adPlan/uploadFile'"
                  :show-upload-list="false"
                  :data="{ type: 1, index: index }"
                  :customRequest="customRequest"
                  :headers="header"
                  style="width: 100%"
                >
                  <!-- <img :src="adplanResourceUrl + sourceFormData[index].sourceForm.imageUrl" alt="图片" style="width: 100%" /> -->
                  <div>
                    <a-icon :type="sourceFormData[index].sourceForm.loading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
                <div v-if="sourceFormData[index].sourceForm.positionId !== 2" class="greyBox image_grey">
                  <span>【推荐】宽高比列: 9:16、16:9 推荐尺寸720x1280、</span>
                  <span> 1280x720、大小不超过300KB,</span>
                  <span>格式: png jpg jpeg</span>
                </div>
                <div v-if="sourceFormData[index].sourceForm.positionId === 2" class="greyBox image_grey">
                  <span>【推荐】推荐宽高比例: 6.4:1，推荐尺寸320x50、</span>
                  <span> 640x100、大小不超过300KB,</span>
                  <span>格式: png jpg jpeg</span>
                </div>
              </a-form-model-item>
              <a-form-model-item
                label="ICON图片"
                prop="iconUrl"
                class="hiddenError"
                v-if="
                  !(
                    [1, 2, 3].includes(sourceFormData[index].sourceForm.positionId) ||
                    sourceFormData[index].sourceForm.type == 1 ||
                    sourceFormData[index].sourceForm.type == 2
                  ) ||
                    ([5].includes(sourceFormData[index].sourceForm.positionId) &&
                    sourceFormData[index].sourceForm.type == 2)
                "
                :rules="[
                  {
                    required:
                      [5].includes(sourceFormData[index].sourceForm.positionId) ||
                      sourceFormData[index].sourceForm.type == 2,
                    message: '此项为必填项',
                    trigger: 'change',
                    validator: handleImgErr
                  }
                ]"
              >
                <div v-if="sourceFormData[index].sourceForm.iconUrl" class="elImageBox image_box">
                  <el-image
                    style="height: 100px"
                    :src="adplanResourceUrl + sourceFormData[index].sourceForm.iconUrl"
                    :preview-src-list="[
                      adplanResourceUrl + adplanResourceUrl + sourceFormData[index].sourceForm.iconUrl
                    ]"
                  >
                  </el-image>
                  <div class="modal">
                    <div class="wrapper">
                      <a-icon class="deleteIcon" type="delete" @click="() => handleCloseImg(2, index)" />
                      <a-icon
                        class="deleteIcon"
                        type="eye"
                        @click="() => handlePreviewImg(adplanResourceUrl + sourceFormData[index].sourceForm.iconUrl, 1)"
                      />
                    </div>
                  </div>
                </div>
                <a-upload
                  v-else
                  name="file"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :show-upload-list="false"
                  :before-upload="beforeIconUpload"
                  :customRequest="customRequest"
                  :data="{ type: 2, index }"
                >
                  <!-- <img v-if="" :src="" alt="图片" style="width: 120px" /> -->
                  <div>
                    <a-icon :type="sourceFormData[index].sourceForm.iconloading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
                <div class="greyBox image_grey">推荐尺寸: 1:1以内的方形图,大小不超过200KB, 格式: png jpg jpeg</div>
              </a-form-model-item>
            </div>
            <div class="flex-1">
              <a-form-model-item
                label="创意标题"
                prop="title"
                v-if="sourceFormData[index].sourceForm.positionId !== 2"
                :rules="{
                  required:
                    sourceFormData[index].sourceForm.positionId === 5 ||
                    sourceFormData[index].sourceForm.type == 2 ||
                    sourceFormData[index].sourceForm.positionId === 9
                      ? true
                      : false,
                  message: '此项为必填项',
                  trigger: 'change'
                }"
              >
                <a-input v-model.trim="sourceFormData[index].sourceForm.title" allowClear placeholder="创意标题" />
              </a-form-model-item>
              <a-form-model-item
                label="创意描述"
                v-if="sourceFormData[index].sourceForm.positionId !== 2"
                :rules="{
                  required:
                    sourceFormData[index].sourceForm.positionId === 5 ||
                    sourceFormData[index].sourceForm.type == 2 ||
                    sourceFormData[index].sourceForm.positionId === 9
                      ? true
                      : false,
                  message: '此项为必填项',
                  trigger: 'change'
                }"
              >
                <a-input v-model.trim="sourceFormData[index].sourceForm.description" allowClear placeholder="创意描述" />
              </a-form-model-item>
              <a-form-model-item
                label="按钮文案"
                v-if="sourceFormData[index].sourceForm.positionId !== 2"
                :rules="{
                  required:
                    sourceFormData[index].sourceForm.positionId === 5 ||
                    sourceFormData[index].sourceForm.type == 2 ||
                    sourceFormData[index].sourceForm.positionId === 9
                      ? true
                      : false,
                  message: '此项为必填项',
                  trigger: 'change'
                }"
              >
                <a-input v-model.trim="sourceFormData[index].sourceForm.buttonText" allowClear placeholder="按钮文案" />
              </a-form-model-item>
              <a-form-model-item
                label="视频"
                prop="videoUrl"
                class="hiddenError"
                v-if="
                  !(
                    [1, 2, 3, 9].includes(sourceFormData[index].sourceForm.positionId) ||
                    sourceFormData[index].sourceForm.type == 1
                  )
                "
                :rules="[
                  {
                    required:
                      [5, 9].includes(sourceFormData[index].sourceForm.positionId) ||
                      sourceFormData[index].sourceForm.type == 2,
                    message: '此项为必填项',
                    trigger: 'change',
                    validator: handleImgErr
                  }
                ]"
              >
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :show-upload-list="false"
                  :before-upload="beforeVideoUpload"
                  :customRequest="customRequest"
                  :data="{ type: 3, index }"
                >
                  <div class="elImageBox" v-if="sourceFormData[index].sourceForm.videoUrl">
                    <video
                      :src="adplanResourceUrl + sourceFormData[index].sourceForm.videoUrl"
                      style="height: 100px"
                    ></video>
                    <div class="modal">
                      <div class="wrapper">
                        <a-icon
                          class="deleteIcon"
                          type="delete"
                          @click.stop="sourceFormData[index].sourceForm.videoUrl = ''"
                        />
                        <a-icon
                          class="deleteIcon"
                          type="eye"
                          @click.stop="
                            () => handlePreviewImg(adplanResourceUrl + sourceFormData[index].sourceForm.videoUrl, 2)
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <a-icon :type="sourceFormData[index].sourceForm.videoloading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
                <div class="greyBox">宽高比列：9:16、16:9，大小&lt;=10M，5s&lt;=时长&lt;=50s，支持MP4</div>
              </a-form-model-item>
              <a-form-model-item label="状态" class="text-left">
                <a-radio-group v-model="sourceFormData[index].sourceForm.sts">
                  <a-radio value="A">开启</a-radio>
                  <a-radio value="S">关闭</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="审核状态" class="text-left" v-if="sourceFormData[index].sourceForm.auditSts">
                <a-radio-group v-model="sourceFormData[index].sourceForm.auditSts" disabled>
                  <a-radio value="0" disabled>待审核</a-radio>
                  <a-radio value="1" disabled>审核通过</a-radio>
                  <a-radio value="2" disabled>审核未通过</a-radio>
                </a-radio-group>
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>
      </a-collapse-panel>
    </a-collapse>
    <Empty v-else style="height: 500px">
      <template #description> 暂无数据 </template>
    </Empty>
    <!--  v-if="!$route.query.id" -->
    <div class="m-t-20">
      <a-button
        type="link"
        :size="'small'"
        class="linkBtnStyle"
        @click="(e) => handleCreative(e, 'add')"
      >继续添加创意</a-button
      >
      <span>一个计划可添加多个创意</span>
    </div>
    <!-- <div v-else>
      <span v-if="this.creativeIndex === this.sourceFormList.length">没有了更多创意了</span>
      <a-button v-else type="link" :size="'small'" class="linkBtnStyle" @click="() => handleCreative('see')">一个计划可添加多个创意</a-button>
    </div> -->
    <!-- <a-alert v-if="isAlertErr" type="error" message="此项是必填项" class="alertClass" banner /> -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img v-if="previewType === 1" alt="example" style="width: 100%; max-height: 600px" :src="previewImage" />
      <video v-else :src="previewImage" controls style="width: 100%; max-height: 600px"></video>
    </a-modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { getAdPlanCreativeById, uploadFile, updateAdPlanCreativeSts } from '@/api/advertisement'
import Empty from '@/components/common/empty'
export default {
  name: 'SourceMaterial',
  components: { Empty },
  props: {
    planId: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token
    }),
    ...mapGetters(['adplanResourceUrl']),
    header () {
      return {
        Authorization: this.token
      }
    }
  },
  data () {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      previewVisible: false,
      previewImage: '',
      previewType: 1,
      sourceFormData: [
        {
          sourceForm: {
            positionId: undefined,
            type: undefined,
            name: undefined,
            imageUrl: '',
            iconUrl: '',
            videoUrl: '',
            sts: 'A',
            loading: false,
            iconloading: false,
            videoloading: false
          }
        }
      ],
      sourceForm: {
        imageUrl: '',
        iconUrl: '',
        videoUrl: '',
        sts: 'A'
      },
      creativeTypeOptions: [
        {
          label: '图片',
          value: 1
        },
        {
          label: '视频',
          value: 2
        }
      ],
      creativeTypeVideoOptions: [
        {
          label: '视频',
          value: 2
        }
      ],
      advTypeOptions: [
        {
          label: '开屏',
          value: 1
        },
        {
          label: '横幅',
          value: 2
        },
        {
          label: '插屏',
          value: 3
        },
        {
          label: '激励视频',
          value: 5
        },
        {
          label: '信息流',
          value: 9
        }
      ],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/adPlan/uploadFile',
      routeId: undefined,
      creativeIndex: 0,
      sourceFormList: [],
      isAlertErr: false
    }
  },
  created () {
    const { id } = this.$route.query
    this.routeId = id
    if (id) {
      this.getAdPlanCreativeByIdFn({ id: this.routeId })
    }
    this.sourceFormData = [
      {
        sourceForm: {
          imageUrl: '',
          iconUrl: '',
          videoUrl: '',
          sts: 'A',
          loading: false,
          iconloading: false,
          videoloading: false
        }
      }
    ]
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    handlePreviewImg (src, type) {
      this.previewImage = src
      this.previewVisible = true
      this.previewType = type
    },
    // 根据广告计划id查询素材详情
    async getAdPlanCreativeByIdFn (data) {
      const res = await getAdPlanCreativeById(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        if (tempData.length !== 0) {
          this.sourceFormData = []
          tempData.forEach((item) => {
            this.sourceFormData.push({ sourceForm: item })
          })
        }
      }
    },
    // 关闭素材
    async updateAdPlanCreativeStsFn (data, index) {
      const res = await updateAdPlanCreativeSts(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.sourceFormData.splice(index, 1)
      }
    },
    // // 编辑上传创意
    // async updateCreativeFn (data) {
    //   const res = await updateCreative(data)
    //   if (res.code === 200) {
    //     // if (this.creativeIndex === this.sourceFormList.length) {
    //     //   this.creativeIndex = this.sourceFormList.length
    //     //   return
    //     // }
    //     // this.creativeIndex = this.creativeIndex + 1
    //     // this.creativeIndex <= this.sourceFormList.length - 1 && this.setSourceForm()
    //   }
    // },
    // 素材上传
    // async uploadCreativeFn (data) {
    //   const res = await uploadCreative(data)
    //   if (res.code === 200) {
    //     this.sourceFormData = [{
    //       sourceForm: {
    //         imageUrl: '',
    //         iconUrl: '',
    //         videoUrl: '',
    //         sts: 'A',
    //         loading: false,
    //         iconloading: false,
    //         videoloading: false
    //       }
    //     }]
    //   }
    // },
    // 上传文件
    async uploadFileFn (querytData, formData) {
      const res = await uploadFile(formData)
      const tempData = res.data
      const { type, index } = querytData
      if (res.code === 200 && res.data) {
        if (type === 1) {
          this.sourceFormData[index].sourceForm = {
            ...this.sourceFormData[index].sourceForm,
            imageUrl: tempData.fileUrl,
            imageWidth: tempData.width,
            imageHeight: tempData.height,
            imageSize: tempData.kbSize
          }
        } else if (type === 2) {
          this.sourceFormData[index].sourceForm = {
            ...this.sourceFormData[index].sourceForm,
            iconUrl: tempData.fileUrl,
            iconWidth: tempData.width,
            iconHeight: tempData.height,
            iconSize: tempData.kbSize
          }
        } else if (type === 3) {
          this.sourceFormData[index].sourceForm = {
            ...this.sourceFormData[index].sourceForm,
            videoUrl: tempData.fileUrl,
            videoWidth: tempData.width,
            videoHeight: tempData.height,
            videoSize: tempData.kbSize,
            videoDuration: tempData.duration
          }
        }
      } else {
        this.sourceFormData[index].sourceForm.loading = false
        this.sourceFormData[index].sourceForm.iconloading = false
        this.sourceFormData[index].sourceForm.videoloading = false
        // this.$message.error(res.msg)
      }
    },
    // setSourceForm () {
    //   this.sourceForm = this.sourceFormList[this.creativeIndex] || {
    //     imageUrl: '',
    //     iconUrl: '',
    //     videoUrl: '',
    //     sts: 'A'
    //   }
    // },
    handleCreative (e, type, index) {
      e.stopPropagation()
      if (type === 'add') {
        this.sourceFormData.push({
          sourceForm: {
            positionId: undefined,
            type: undefined,
            name: undefined,
            imageUrl: '',
            iconUrl: '',
            videoUrl: '',
            sts: 'A',
            loading: false,
            iconloading: false,
            videoloading: false
          }
        })
      } else {
        // 删除
        const self = this
        const { sourceForm } = this.sourceFormData[index]
        if (sourceForm.id) {
          this.$confirm({
            title: '提示',
            content: '您确定要删除该素材吗?',
            onOk () {
              self.updateAdPlanCreativeStsFn({ id: sourceForm.id, sts: 'D' }, index)
            },
            onCancel () {
              self.$message.info('取消操作')
            }
          })
        } else {
          this.sourceFormData.splice(index, 1)
        }
      }
    },
    handleImgErr (rule, value, callback) {
      setTimeout(() => {
        this.isAlertErr = false
      }, 1000)
      if (!value) {
        this.isAlertErr = true
        return callback(new Error("Two inputs don't match!"))
      } else {
        callback()
        return false
      }
    },
    // handleChange (info, fileList, event) {
    //   if (info.file.status === 'uploading') {
    //     this.loading = true
    //     return
    //   }
    //   if (info.file.status === 'done' && info.file.response.code === 200) {
    //     // this.sourceForm.imageUrl = info.file.response.data.fileUrl
    //     this.sourceForm = {
    //       ...this.sourceForm,
    //       imageUrl: info.file.response.data.fileUrl,
    //       imageWidth: info.file.response.data.width,
    //       imageHeight: info.file.response.data.height,
    //       imageSize: info.file.response.data.kbSize
    //     }
    //     this.loading = false
    //   } else {
    //     this.loading = false
    //     this.$message.error(info.file.response.msg)
    //   }
    // },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请选择上传 JPG JPEG PNG 格式的图片!')
      }
      const isLt300 = file.size / 1024 < 301
      if (!isLt300) {
        this.$message.error('文件大小不能超过300kb!')
      }
      return isJpgOrPng && isLt300
    },
    // icon
    beforeIconUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请选择上传 JPG JPEG PNG 格式的图片!')
      }
      const isLt200 = file.size / 1024 < 201
      if (!isLt200) {
        this.$message.error('文件大小不能超过200kb!')
      }
      return isJpgOrPng && isLt200
    },
    handleCloseImg (type, index) {
      if (type === 1) {
        this.sourceFormData[index].sourceForm.imageUrl = ''
      } else {
        this.sourceFormData[index].sourceForm.iconUrl = ''
      }
    },
    // handleIconChange (info) {
    //   if (info.file.status === 'uploading') {
    //     this.iconloading = true
    //     return
    //   }
    //   if (info.file.status === 'done' && info.file.response.code === 200) {
    //     this.sourceForm.iconUrl = info.file.response.data.fileUrl
    //     this.sourceForm = {
    //       ...this.sourceForm,
    //       iconUrl: info.file.response.data.fileUrl,
    //       iconWidth: info.file.response.data.width,
    //       iconHeight: info.file.response.data.height,
    //       iconSize: info.file.response.data.kbSize
    //     }
    //     this.iconloading = false
    //   } else {
    //     this.iconloading = false
    //     this.$message.error(info.file.response.msg)
    //   }
    // },
    beforeVideoUpload (file) {
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      // if (!isJpgOrPng) {
      //   this.$message.error('请选择上传 JPG JPEG PNG 格式的图片!')
      // }
      // const isLt300 = file.size / 1024 < 301
      // if (!isLt300) {
      //   this.$message.error('文件大小不能超过300kb!')
      // }
      // return isJpgOrPng
    },
    // handleVideoChange (info) {
    //   if (info.file.status === 'uploading') {
    //     this.videoloading = true
    //     return
    //   }
    //   if (info.file.status === 'done' && info.file.response.code === 200) {
    //     this.sourceForm.videoUrl = info.file.response.data.fileUrl
    //     this.sourceForm = {
    //       ...this.sourceForm,
    //       videoUrl: info.file.response.data.fileUrl,
    //       videoWidth: info.file.response.data.width,
    //       videoHeight: info.file.response.data.height,
    //       videoSize: info.file.response.data.kbSize,
    //       videoDuration: info.file.response.data.duration
    //     }
    //     this.videoloading = false
    //   } else {
    //     this.videoloading = false
    //     this.$message.error(info.file.response.msg)
    //   }
    // },
    customRequest (options) {
      const { filename, file, data, onSuccess } = options
      // 定义上传的文件
      const formData = new FormData()
      formData.append(filename, file)
      formData.append('type', data.type)
      this.uploadFileFn(data, formData)
      onSuccess()
    },
    handlepositionId (index) {
      this.sourceFormData[index].sourceForm = {
        ...this.sourceFormData[index].sourceForm,
        type: undefined
      }
    }
    // isSize (file, width, height) {
    //   // const self = this
    //   return new Promise(function (resolve, reject) {
    //     const filereader = new FileReader()
    //     filereader.onload = (e) => {
    //       const src = e.target.result
    //       const image = new Image()
    //       image.onload = function () {
    //         if ((width && this.width !== width) || (height && this.height !== height)) {
    //           // reject()
    //         } else {
    //           resolve()
    //         }
    //       }
    //       image.onerror = reject
    //       image.src = src
    //     }
    //     filereader.readAsDataURL(file)
    //   })
    // }
  }
}
</script>
<style lang="less" scoped>
.flexStyle {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.text-left {
  text-align: left;
}
.m-t-20 {
  margin-top: 30px;
}
.greyBox {
  background: #f2f2f2;
  border-radius: 5px;
  padding: 12px 10px;
  line-height: 1.5;
  color: #b2b2b2;
  text-align: left;
  font-size: 13px;
  &.image_grey {
    margin-top: 10px;
  }
}
.linkBtnStyle {
  border-bottom: 1px solid;
  border-radius: 0;
}
::v-deep .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  & > .ant-upload {
    padding: 0;
  }
}
.elImageBox {
  &.image_box {
    border: 1px dashed #ccc;
  }
  position: relative;
  display: flex;
  justify-content: center;
  .modal {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &:hover .modal {
    display: block;
  }
  .deleteIcon {
    font-size: 20px;
    margin-left: 10px;
    color: #eee;
  }
}
.alertClass {
  position: fixed;
  top: 20%;
  left: 50%;
  width: 200px;
}
.hiddenError {
  ::v-deep .ant-form-explain {
    display: none;
  }
}
::v-deep.ant-form-item {
  margin-bottom: 20px;
}
</style>
